body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 1024px) {
  .dropdown-menu {
    max-height: 60vh;
    max-width: 60vw;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-filter: drop-shadow(0px 15px 20px rgb(24, 24, 24));
    filter: drop-shadow(0px 15px 20px rgb(24, 24, 24));
    border: 1px solid rgb(116, 116, 116);
    border-radius: 5px;
    min-width: 250px;
  }
}
@media (max-width: 1024px) {
  .dropdown-menu {
    position: fixed !important;
    top: 0 !important;
    left: -5px !important;
    width: calc(100vw - 25px);
    height: calc(100vh - 150px);
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-filter: drop-shadow(0px 15px 20px rgb(24, 24, 24));
    filter: drop-shadow(0px 15px 20px rgb(24, 24, 24));
    border: 1px solid rgb(116, 116, 116);
    border-radius: 5px;
    min-width: 250px;
  }
}