.teamFilterButton,
.teamFilterButton:focus {
    background-color: rgb(34, 34, 34);
    border-color: #555;
    padding: 6px 13px 6px 13px;
}

.centerContainer {
  max-width: 1024px;
  margin: 0 auto; /* Centers the container */
  width: 100%; /* Uses 100% of the viewport width up to the max-width */
  padding: 20px; /* Optional padding for aesthetics */
}

.inlineContainer {
  display: flex;
  align-items: center; /* Aligns items vertically in the middle */
  gap: 10px; /* Adds some space between the label and the dropdown */
}

.inlineLabel {
  margin-right: 10px; /* Optional: Adds space between the label and dropdown */
}

/* Lander.module.css */
.iframeTextarea {
  width: 100%; /* Ensure it takes full container width */
  margin-top: 20px; /* Space from the component above */
  padding: 10px 10px; /* Padding inside the textarea for better text visibility */
  border: 1px solid #555; /* Darker border for contrast on dark backgrounds */
  border-radius: 8px; /* Rounded corners */
  background-color: #2a2a2a; /* Dark background color for the textarea */
  box-shadow: 0 2px 4px rgba(0,0,0,0.5); /* Enhanced shadow for depth in dark themes */
  font-family: 'Courier New', monospace; /* Monospaced font for better alignment of iframe code */
  font-size: 14px; /* Appropriate font size for readability */
  line-height: 1.5; /* Line height for better readability */
  color: #ccc; /* Light gray color for text to contrast against the dark background */
  resize: none; /* Disable resizing to maintain layout integrity */
  text-align: center;
}

/* Optional: adding focus style for better UX */
.iframeTextarea:focus {
  border-color: #9cadb7;
  outline: none; /* Remove default focus outline */
  box-shadow: 0 0 0 0.2rem rgba(130,138,145,0.25); /* Subtle blue glow to indicate focus, works well on dark themes */
}

h2 {
  font-size: 1.25em; /* Larger font size for headings */
  margin-top: 50px;
}

@media (max-width: 768px) { /* Adjust this breakpoint as needed for mobile */
  .centerContainer {
    max-width: none; /* Allows the container to use the full screen on mobile */
    padding: 10px; /* Reduces padding on smaller screens */
  }

  .inlineContainer {
    flex-direction: column; /* Stacks label and dropdown vertically on smaller screens */
    align-items: flex-start; /* Aligns items to the left */
  }

  .inlineLabel {
    margin-bottom: 5px; /* Adds a little space between the label and the dropdown */
  }

  .iframeTextarea {
    font-size: 8px; /* Appropriate font size for readability */
  }
}
