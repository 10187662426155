.matchesTableContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  min-height: 100%;
  border-radius: 5px;
  background-color: #1d1d1d; /* dark background */
  color: #ddd; /* light text color */
}

.matchesTableHeader {
  height: clamp(25px, 10vmin, 50px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border-bottom: 1px dashed #444444;
  background-color: #222;
  color: #888;
  flex-shrink: 0;
  font-size: clamp(10px, 1.2vw, 20px);
  filter: drop-shadow(0 0 20px rgb(15, 15, 15));
}

.matchesTableFooter {
  height: clamp(25px, 10vmin, 50px);
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px dashed #444444;
  background-color: #222;
  color: #888;
  flex-shrink: 0;
  font-size: clamp(10px, 1.2vw, 20px);
  filter: drop-shadow(0 0 20px rgb(15, 15, 15));
}

.matchesTable {
  flex: 1; /* takes the remaining space */
  overflow-y: auto; /* enables scrolling */
  padding: 10px;
  font-size: clamp(12px, 2vw, 20px);
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.matchRow {
  display: grid;
  grid-template-columns: 2fr 2fr 5vw 4fr; /* adjust based on your design needs */
  grid-gap: 10px;
  align-items: center;
  padding: 10px 0;
}

.team, .vs, .opponent {
  display: flex;
  align-items: center;
}

.opponent {
  justify-content: left;
}

.vs {
  justify-content: center;
}

.team {
  justify-content: right;
}

.vs {
  color: #555; 
}

.date {
  min-width: 15vw;
  text-align: left;
  margin-lefT: 20px;
}

.datePart {
  color: #aaa;
}

.timePart {
  color: rgb(126, 131, 87)
}

.opponentLabel {
  margin-left: 2vw;
}

.teamLabel {
  margin-right: 2vw;
}

.vs {
  justify-content: center;
  font-weight: bold;
}

a {
  color: #c0c0c0; /* lighter grey for links to stand out a bit */
  text-decoration: none; /* no underline */
}

a:hover {
  text-decoration: underline; /* underline on hover */
}

img {
  vertical-align: middle;
}

.lensLogo {
  margin-left: 8px;
  opacity: 0.7;
  position: relative;
  top: -1px;
  height: clamp(10px, 2vw, 16px);
}

@media (max-width: 480px) {
  .matchRow {
    display: grid;
    grid-template-columns: 1fr; /* Sets the layout to a single column */
    grid-gap: 10px;
    align-items: center;
    padding: 10px 0;
  }

  .vs {
    display: none; /* Hides the VS column */
  }

  .date {
    text-align: left;
    border-bottom: 1px solid #555;
    margin-left: 0px;
  }

  .datePart, .timePart {
    display: inline-block;
  }

  .timePart {
    margin-left: 5px;
    margin-top: 5px;
  }

  .opponent, .team {
    justify-content: left;
  }

  .opponentLabel {
    margin-left: 2vw;
    margin-right: 0;
  }
  
  .teamLabel {
    margin-left: 2vw;
    margin-right: 0;
  }
}
